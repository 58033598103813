import React, { useState } from "react";
// import Recaptcha from "react-recaptcha";
import { useStaticQuery, graphql } from "gatsby";
import GoogleMapReact from "google-map-react";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import { Toaster, toast } from "react-hot-toast";

const ContactPage = ({ pageContext }) => {

  const [inquiry, setInquiry] = useState({
    patientName: '',
    emailAddress: '',
    phoneNumber: '',
    newsletterSignUp: true,
    preferredContactMethod: '',
    visitedBefore: null,
    acceptToS: true,
    message: ''
  });

  const data = useStaticQuery(graphql`
    query contactPage {
      firestore {
        en: settings(locale: "en") {
          tel
          email
          whatsapp
          map {
            url
            apiKey
            zoom
            lng
            lat
          }
        }
        ch: settings(locale: "ch") {
          tel
          email
          whatsapp
          map {
            url
            apiKey
            zoom
            lng
            lat
          }
        }
      }
    }
  `)

  const translations = {
    en: {
      headerTitle: 'Contact Us',
      bookAppointmentText: 'Book an Appointment',
      contactFormTitle: 'Contact Form',
      patientName: "Patient's Full Name",
      emailAddress: "Email Address",
      phoneNumber: "Phone Number",
      preferredMethodQuestionText: "What is your preferred method of contact?",
      visitedBeforeQuestionText: "Have you been to our clinic before?",
      yes: 'Yes',
      no: 'No',
      email: 'Email',
      phone: 'Phone',
      message: 'Message',
      newsletter: 'I would like to receive health-related information from Hong Kong Spine Centre via email.',
      privacy: 'I have read and understood my ',
      submit: 'Send Message',
      loading: 'Processing...',
      success: 'We have received your enquiry and will get back to you shortly!',
      error: 'There was an error occurred during submission, please try again later.',
      invalidated: 'Please make sure the form is filled correctly and accept our privacy policy.'
    },
    ch: {
      headerTitle: '聯絡我們',
      bookAppointmentText: '預約診症時間',
      contactFormTitle: '有疑問？我們很樂意為您解答！',
      patientName: "姓名",
      emailAddress: "電郵",
      phoneNumber: "電話",
      preferredMethodQuestionText: "您首選的聯絡方式:",
      visitedBeforeQuestionText: "您曾經在我們診所就診嗎？",
      yes: '有',
      no: '沒有',
      email: '電郵',
      phone: '電話',
      message: '查詢 / 意見',
      newsletter: '我希望透過電郵收到貴診所的健康資訊。',
      privacy: '我已知悉並接受我的',
      submit: '提交',
      loading: '處理中...',
      success: '我們已經收到你的查詢！',
      error: '提交過程中發生錯誤，請稍後再試。',
      invalidated: '請確保所輸入的資料正確及已接受隱私權利條款。'
    }
  }

  const renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
      position: {
        lat: data.firestore[pageContext.locale]["map"]["lat"],
        lng: data.firestore[pageContext.locale]["map"]["lng"]
      },
      map,
      title: 'Hong Kong Spine Centre'
    });
    return marker;
  };

  return (
    <Layout context={pageContext} openGraph={{
      url: pageContext.localizedUrls[pageContext.locale],
      title: pageContext.title,
      description: pageContext.description,
      images: [
        {
          url: 'https://www.spinecentre.com.hk/_img/banners/contact.jpg'
        }
      ]
    }}>
      <PageHeader
        title={translations[pageContext.locale]['headerTitle']}
        imageUrl="/_img/banners/contact.jpg"
      />
      <main className="my-16 flex flex-col items-center">
        <h1 className="font-light text-xl lg:text-3xl text-coconut">{translations[pageContext.locale]['bookAppointmentText']}</h1>
        <ul className="flex flex-col lg:flex-row items-center space-x-4 mt-8">
          <li className="text-brick flex text-xl lg:text-2xl items-center">
            <svg className="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
            </svg>
            <a className="text-mango" href={`tel:${data.firestore[pageContext.locale]["tel"].split(' ').join('')}`}>{data.firestore[pageContext.locale]["tel"]}</a>
          </li>
          <li className="flex text-xl lg:text-2xl items-center">
            <svg className="w-6 h-6 mr-2 text-brick" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
            </svg>
            <a href={`mailto:${data.firestore[pageContext.locale]["email"]}`} className="text-mango">
              {data.firestore[pageContext.locale]["email"]}
            </a>
          </li>
          <li className="flex text-xl lg:text-2xl items-center">
            <svg className="h-6 w-6 text-brick" fill="currentColor" aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" >
              <path fill="currentColor" d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z">
              </path>
            </svg>
            <a href={`${data.firestore[pageContext.locale]["whatsapp"]}`} className="text-mango">
              &nbsp;WhatsApp
            </a>
          </li>
        </ul>
        <div className="my-8 w-full lg:w-5/6 h-[320px] lg:h-[480px]">
          <GoogleMapReact
            bootstrapURLKeys={{ key: data.firestore[pageContext.locale]["map"]["apiKey"] }}
            defaultCenter={{
              lat: data.firestore[pageContext.locale]["map"]["lat"],
              lng: data.firestore[pageContext.locale]["map"]["lng"]
            }}
            onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
            defaultZoom={19}
          />
        </div>
        <h1 className="text-brick text-xl lg:text-3xl text-center">{translations[pageContext.locale]['contactFormTitle']}</h1>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (inquiry.acceptToS && inquiry.preferredContactMethod.length > 0 && inquiry.visitedBefore !== null && inquiry.message.length > 0) {
              toast.promise(
                fetch('https://api.spinecentre.com.hk/v1/inquiries', {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify(inquiry),
                }),
                {
                  loading: translations[pageContext.locale]['loading'],
                  success: translations[pageContext.locale]['success'],
                  error: translations[pageContext.locale]['error']
                }
              );
            } else {
              toast.error(
                translations[pageContext.locale]['invalidated']
              )
            }
          }}
          className="w-full px-8 lg:px-0 lg:w-2/5 mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
          <div className="sm:col-span-2">
            <div className="mt-1">
              <input
                type="text"
                name="patientName"
                value={inquiry.patientName}
                id="patientName"
                placeholder={translations[pageContext.locale]['patientName']}
                onChange={(e) => setInquiry({ ...inquiry, [e.target.name]: e.target.value })}
                className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-mango focus:border-mango border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div>
            <div className="mt-1">
              <input
                id="emailAddress"
                name="emailAddress"
                type="email"
                value={inquiry.emailAddress}
                autoComplete="email"
                onChange={(e) => setInquiry({ ...inquiry, [e.target.name]: e.target.value })}
                placeholder={translations[pageContext.locale]['emailAddress']}
                className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-mango focus:border-mango border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div>
            <div className="mt-1">
              <input
                type="text"
                name="phoneNumber"
                id="phoneNumber"
                value={inquiry.phoneNumber}
                autoComplete="tel"
                placeholder={translations[pageContext.locale]['phoneNumber']}
                onChange={(e) => setInquiry({ ...inquiry, [e.target.name]: e.target.value })}
                className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-mango focus:border-mango border-gray-300 rounded-md"
                aria-describedby="phone-optional"
              />
            </div>
          </div>
          <div>
            <label htmlFor="email" className="block text-lg font-medium text-coconut">
              {translations[pageContext.locale]['preferredMethodQuestionText']}
            </label>
            <div className="mt-1" onChange={(e) => setInquiry({ ...inquiry, [e.target.name]: e.target.value })}>
              <div className="flex items-center">
                <input
                  id="email"
                  name="preferredContactMethod"
                  value="email"
                  type="radio"
                  className="focus:ring-mango h-4 w-4 text-mango border-gray-300"
                />
                <label htmlFor="email" className="ml-3 block text-lg font-medium text-coconut">
                  {translations[pageContext.locale]['email']}
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="phone"
                  name="preferredContactMethod"
                  value="phone"
                  type="radio"
                  className="focus:ring-mango h-4 w-4 text-mango border-gray-300"
                />
                <label htmlFor="phone" className="ml-3 block text-lg font-medium text-coconut">
                  {translations[pageContext.locale]['phone']}
                </label>
              </div>
            </div>
          </div>
          <div onChange={(e) => setInquiry({ ...inquiry, [e.target.name]: e.target.value > 0 })}>
            <label htmlFor="visitedBefore" className="block text-lg font-medium text-coconut">
              {translations[pageContext.locale]['visitedBeforeQuestionText']}
            </label>
            <div className="mt-1">
              <div className="flex items-center">
                <input
                  id="visitedBeforeYes"
                  name="visitedBefore"
                  value={1}
                  type="radio"
                  className="focus:ring-mango h-4 w-4 text-mango border-gray-300"
                />
                <label htmlFor="visitedBeforeYes" className="ml-3 block text-lg font-medium text-coconut">
                  {translations[pageContext.locale]['yes']}
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="visitedBeforeNo"
                  name="visitedBefore"
                  value={0}
                  type="radio"
                  className="focus:ring-mango h-4 w-4 text-mango border-gray-300"
                />
                <label htmlFor="visitedBeforeNo" className="ml-3 block text-lg font-medium text-coconut">
                  {translations[pageContext.locale]['no']}
                </label>
              </div>
            </div>
          </div>
          <div className="sm:col-span-2">
            <div className="mt-1">
              <textarea
                id="message"
                name="message"
                rows={4}
                onChange={(e) => setInquiry({ ...inquiry, [e.target.name]: e.target.value })}
                value={inquiry.message}
                placeholder={translations[pageContext.locale]['message']}
                className="py-3 px-4 block w-full shadow-sm text-warm-gray-900 focus:ring-mango focus:border-mango border border-gray-300 rounded-md"
                aria-describedby="message-max"
              />
            </div>
          </div>
          <div className="flex justify-between flex-col lg:flex-row sm:col-span-2 space-x-4">
            {/* <Recaptcha sitekey="6LdPLY8cAAAAANKQgXk7Irg3Rna4DfJ8Mly7xmQ5" /> */}
            <div className="w-full space-y-4 mt-4 lg:mt-0 flex flex-col justify-evenly">
              <div className="relative flex items-start">
                <div className="flex items-center h-5">
                  <input
                    id="acceptToS"
                    name="acceptToS"
                    type="checkbox"
                    checked={inquiry.acceptToS}
                    onChange={(e) => setInquiry({ ...inquiry, [e.target.name]: e.target.checked })}
                    className="focus:ring-mango h-4 w-4 text-mango border-gray-300 rounded"
                  />
                </div>
                <div className="ml-3 text-base">
                  <label htmlFor="comments" className="font-medium text-gray-700">
                    {translations[pageContext.locale]['privacy']}
                    <a className="text-mango" href={`/${pageContext.locale}/about-us/privacy-legal`}>{pageContext.locale === 'en' ? ' privacy rights' : '隱私權利'}</a>{pageContext.locale === 'en' ? '.' : '。'}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:col-span-2 sm:flex sm:justify-start">
            <button
              type="submit"
              className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base bg-coconut font-medium text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:w-auto"
            >
              {translations[pageContext.locale]['submit']}
            </button>
          </div>
        </form>
        <Toaster />
      </main>
    </Layout>
  )
}

export default ContactPage
